import { Component , createRef} from "preact";

import { ADMIN_FRAME } from "../../globals";
import { editorOverlayAPI } from "./editor-overlay-controller"
import { withScroll } from "../page/scroll-element";
import selectors from "./../../selectors";

import _ from 'lodash';

class SwapItem extends Component {
	constructor(props){
		super(props);

		this.state= {
			dragPos: {x: 0, y: 0},
			referenceDragPos: {x: 0, y: 0},
			swapTarget: null,
			readyForDrop: false,
			draggingOverItem: false,
			rect: {
				top: 0,
				left: 0,
				width: 0,
				height: 0
			}
		}

		this.dropRef = createRef();
		this.onDragOver = _.throttle(this.onDragOver, 80);
		
	}


	showDropTarget = ()=>{

		if(
			Math.abs(this.state.dragPos.x - this.state.referenceDragPos.x) > 10 ||
			Math.abs(this.state.dragPos.y - this.state.referenceDragPos.y) > 10
		){

			this.setState({
				swapTarget: null
			})
			return;
		}

		if ( ADMIN_FRAME.globalDragEventController.customDropTarget !== this.dropRef.current){
			ADMIN_FRAME.globalDragEventController.releaseCustomDropTarget(ADMIN_FRAME.globalDragEventController.customDropTarget);	
			ADMIN_FRAME.globalDragEventController.setCustomDropTarget(this.dropRef.current);
		}

		this.setState({
			readyForDrop: true,
		})

	}

	getRect = ()=>{

		if ( !this.state.swapTarget){
			return;
		}

		this.setState({
			rect: this.state.swapTarget.getBoundingClientRect(),
		})
	
	}

	render(props,state){

		const {
			draggingOverItem,
			readyForDrop,
			rect,
		} = state;		

		const styles = {
			frame: {
				display: readyForDrop ? 'flex' : 'none',
				alignItems: 'center',
				justifyContent: 'center',
				background: 'rgba(255, 255, 255, 0.6)',
				position: 'fixed',
				top: rect.top +'px',
				left: rect.left +'px',
				width: rect.width+'px',
				height: rect.height +'px',
				pointerEvents: 'none',
				zIndex: 999
			},
			replace: {
				pointerEvents: 'none',				
				position: 'relative',
				top: 'unset',
				left: 'unset'
			}
			
		}

		return<div
			ref={this.dropRef}
			style={styles.frame}>
				<button className="media-options-button multiple" style={styles.replace}>Replace</button>
			</div>
	}

	onDragEnd = (editor, e, dragData)=>{
		this.onDragOver.cancel();
		clearTimeout(this.showDropTargetTimer)
		

		this.setState({
			readyForDrop: false,
			draggingOverItem: false,
			swapTarget: false,
		})
	}

	onDragDrop = (editor, e, dragData)=>{
		clearTimeout(this.showDropTargetTimer)
		this.onDragOver.cancel();

		if( this.dropRef.current !== dragData.customDropTarget || !this.state.swapTarget || dragData.inAdmin){
			return
		}

		CargoEditor.mutationManager.execute(()=>{

			const currentState = window.store.getState();
			let target = this.state.swapTarget;
			let source = dragData.draggedMediaItems[0];
			let thumbnailIndex = target.closest('[thumbnail-index]');

			const targetHash = target.getAttribute('hash');
			const sourceHash = source.getAttribute('hash');
			let targetPid = target.closest('.page[id]')?.getAttribute('id');

			if ( thumbnailIndex){
				const href = target.getAttribute('href');
				targetPid = selectors.getContentByPurl(currentState, href)?.id || null;					
			}

			if( !targetPid || !sourceHash ){
				return;
			}

			let srcModel = selectors.getMediaByHash(currentState)[sourceHash] || null;
			let targetModel = selectors.getMediaByHash(currentState)[targetHash] || null;

			if (!srcModel){
				return
			}

			const { CRDTItem: pageCRDT } = ADMIN_FRAME.getCRDTItem({
				reducer: "pages.byId",
				item: targetPid
			});

			let targetPageMedia = currentState.pages.byId[targetPid].media || [];
			const preexistingModel = targetPageMedia.find(model=> model.hash == srcModel.hash);

			if( !preexistingModel ){
				// If the images for the page aren't in a CRDT, create a new Y Array to store them
				if (pageCRDT.get("media") instanceof ADMIN_FRAME.adminWindow.Y.Array === false) {
					const sharedMediaType = new ADMIN_FRAME.adminWindow.Y.Array();
					sharedMediaType.insert(0, pageCRDT.get("media") || []);
					pageCRDT.set("media", sharedMediaType);
				}

				// Convert models from plain objects to proper shared types
				const newModel = ADMIN_FRAME.convertStateToSharedType(srcModel, new ADMIN_FRAME.adminWindow.Y.Map());

				pageCRDT.get('media').push([newModel]);					

			}

			if (thumbnailIndex) {
				
				// set thumb to new 
		        ADMIN_FRAME.applyChangesToYType(pageCRDT, {
		            thumb_media_id: srcModel.id
		        });

			} else {

				const differentModelTypes = srcModel && targetModel &&(
					srcModel.is_video !== targetModel.is_video ||
					srcModel.is_image !== targetModel.is_image ||
					srcModel.is_video !== targetModel.is_video 
				)


				if( targetModel && differentModelTypes ){

					target.removeAttribute('autoplay');
					target.removeAttribute('loop');
					target.removeAttribute('muted');
					target.removeAttribute('poster');
					target.removeAttribute('browser-default');

					if(srcModel.is_video){
					
						if(!srcModel.has_audio_track || srcModel.duration < 15 ){

							target.setAttribute('autoplay', true);
							target.setAttribute('muted', true);
							target.setAttribute('loop', true);

						} else {
							target.setAttribute('browser-default', true);
							target.setAttribute('disable-zoom', true);
							if( srcModel.duration  < 20 ){
								target.setAttribute('loop', true);
							}
						}

						target.setAttribute('autoplay', true)
						target.setAttribute('loop', true)
						target.setAttribute('muted', true)

					}  else if (srcModel.is_url && (srcModel.url_type == "vimeo" || srcModel.url_type == "youtube") ){
						target.setAttribute('browser-default', true);
						target.setAttribute('disable-zoom', true);

						target.setAttribute('muted', false);
						target.setAttribute('loop', false);
					}

				}

				target.removeAttribute('src');					
				target.setAttribute('hash', sourceHash);
				target.removeAttribute('width');
				target.removeAttribute('height');	
			}

		})

		
		this.setState({
			readyForDrop: false,
			swapTarget: null,
			draggingOverItem: false,
		})
	

	}



	onBeforeDrop = (editor, e, dragData)=>{
		if( !dragData.fromAdmin && !dragData.fromOutside){
			return;
		}
		if( this.state.readyForDrop && this.state.swapTarget?.nodeName ==='MEDIA-ITEM' && dragData.draggedMediaItems.length === 1){
			ADMIN_FRAME.globalDragEventController.releaseCustomDropTarget(dragData.customDropTarget)		
			ADMIN_FRAME.globalDragEventController.setCustomDropTarget(this.dropRef.current);
		}

	}

	onDragOver = (editor, e, dragData)=>{
		const dropTarget = e.target.closest('MEDIA-ITEM');

		const draggedItemIsValid = (
			dragData.draggedMediaItems.length === 1 &&
			dragData.draggedMediaItems[0] !== dropTarget &&
			!dragData.draggedMediaItems.some((el)=>el.getAttribute('hash') ==='placeholder') &&
			CargoEditor.getActiveEditor().getElement().contains(dropTarget) &&
			dragData.draggedMediaItems[0]?.getAttribute('hash') !== dropTarget?.getAttribute('hash')
		)

		const dragEventIsValid = (
			!dragData.inAdmin && dragData.fromAdmin
		)

		if(
			dropTarget && dragEventIsValid && draggedItemIsValid
		){

			let dragPosChangedSignificantly = (
				Math.abs(this.state.dragPos.x -dragData.cursorPosition.x) > 2 ||
				Math.abs(this.state.dragPos.y -dragData.cursorPosition.y) > 2
			)

			// if there's a new target, reset the drop-progress state
			if ( this.state.swapTarget !== dropTarget || dragPosChangedSignificantly){

				ADMIN_FRAME.globalDragEventController.releaseCustomDropTarget(this.dropRef.current);
				clearTimeout(this.showDropTargetTimer)

				this.setState({
					dragPos: {...dragData.cursorPosition},
					referenceDragPos: {...dragData.cursorPosition},
					readyForDrop:false,					
					draggingOverItem: true,
					swapTarget: dropTarget,
				});
				this.showDropTargetTimer = setTimeout(this.showDropTarget, 400)

			} else {

				// otherwise, keep track of drag position
				this.setState({
					dragPos: {...dragData.cursorPosition},
				});
			}

			this.getRect();

		} else {

			ADMIN_FRAME.globalDragEventController.releaseCustomDropTarget(this.dropRef.current);
			clearTimeout(this.showDropTargetTimer)

			this.setState({
				readyForDrop:false,
				draggingOverItem: false,
				swapTarget: null,
				dragPos: {...dragData.cursorPosition},				
			})
		}
	}

	componentDidMount(){

		this.findDragController().then(()=>{
	 		ADMIN_FRAME.globalDragEventController.on('dragover', this.onDragOver);
	 		ADMIN_FRAME.globalDragEventController.on('drop', this.onDragDrop);
	 		ADMIN_FRAME.globalDragEventController.on('dragend', this.onDragEnd);
			ADMIN_FRAME.globalDragEventController.on('before-drop', this.onBeforeDrop);
		});
	}

	componentWillUnmount(){
		this.onDragOver.cancel();
		this.getRect.cancel();
 		ADMIN_FRAME.globalDragEventController.off('dragover', this.onDragOver);
 		ADMIN_FRAME.globalDragEventController.off('drop', this.onDragDrop)
 		ADMIN_FRAME.globalDragEventController.off('dragend', this.onDragEnd)
		ADMIN_FRAME.globalDragEventController.off('before-drop', this.onBeforeDrop) ;
	}

	componentDidUpdate(prevProps, prevState){
		if(
			this.state.swapTarget !== prevState.swapTarget ||
			this.props.scrollContext.scrollingElement !== prevProps.scrollContext.scrollingElement 
		) {

			if( this.props.scrollContext.scrollingElement && this.state.swapTarget ){

				this.props.scrollContext.scrollingElement.addEventListener('scroll', this.onScroll)

			} else if(prevProps.scrollContext.scrollingElement){

				prevProps.scrollContext.scrollingElement.removeEventListener('scroll', this.onScroll);	
			}

			this.onScroll();

		}		
	}

	onScroll = ()=>{
		if(this.ticking){
			return 
		}

		this.rectFrame = requestAnimationFrame(()=>{
			this.getRect();
			this.ticking = false;
		});

		this.ticking = true;
	}

	findDragController =()=>{

		return new Promise((resolve, reject)=>{

			let findControllerAttempts = 0;
			let dragControllerCheckInterval = null;
			const lookForDragController = ()=>{
		
				let dragController = ADMIN_FRAME.globalDragEventController || null;
		
				if( !dragController ){
					findControllerAttempts++
				} else {
					clearInterval(dragControllerCheckInterval);					
					resolve(dragController);
				}

				if(findControllerAttempts > 200){
					clearInterval(dragControllerCheckInterval);
					reject();
				}

			};

			dragControllerCheckInterval = setInterval(lookForDragController, 300)
			lookForDragController();

		});	
	
	}	

}

export default withScroll(SwapItem)
