import { Component , cloneElement, toChildArray, createRef} from "preact";
import { connect } from 'react-redux';
import { withPageInfo } from "./../page/page-info-context";

import { createPortal } from "preact/compat"
import { ADMIN_FRAME } from "../../globals";
import { editorOverlayAPI } from "./editor-overlay-controller"
import EditorOverlay from "./editor-overlay";
import SelectionStatus from "./selection-status";
import windowInfo from "@cargo/common/window-info"
import * as helpers from "@cargo/common/helpers";

import _ from 'lodash';

const editorSet = new Set();

// if the mouse/pointer goes down inside the window and it isn't captured & its propagation stopped by the handlers
// void out the column set overlay pointer events so we can drag freely
if( !helpers.isServer ){
	window.addEventListener('mousedown', (e)=>{
		editorSet.forEach(component=>{
			component.setState({noPointerEvents: true});
		});
	});
	window.addEventListener('mouseup', (e)=>{
		editorSet.forEach(component=>{
			component.setState({noPointerEvents: false});
		});
	});
}


class ColumnEditor extends Component {
	constructor(props){
		super(props);

		// width of drag area in middle of gutter
		this.handleWidth = 20;

		// make sure handle hangs over gutter at least by this much
		this.minHandleOverlap = 3;
		this.maxColumns = 12;

		this.state= {
			columnSetAbove: false,
			noPointerEvents: false,
			buttonHovered: false,
			focused: false,			
			loaded: false,
			adminWindow: false,
			showGhost: false,
			isDraggingHandle: false,
			isDragging: false,
			activeIndex: -1,
			pointerStart: {x: 0, y: 0},
			delta: {x: 0, y: 0},
			instanceRect: {width: 100, height: 100, left: -999, top: -999},
			referenceColumnSpans: [],
		}


		this.columnAddOrRemoveObserver = new MutationObserver(this.onColumnAddOrRemove)
		this.columnAddOrRemoveObserverConfig = { attributes: true, childList: true, subtree: false };
		this.columnObserver = new MutationObserver(this.onColumnChange)
		this.columnObserverConfig = { attributeFilter : ['span'], childList: false, subtree: false };

		this.columnCache = [];
		this.columnOutlineRef = createRef();

		this.gearReaf = createRef();

		this.addThreshold = 0;
		this.removeThreshold = 0;
		
		this.saveColumnSettings = _.debounce(this.saveColumnSettings, 150);
		this.updateColumns = _.throttle(this.updateColumns, 40);

	}

	render(props,state){
		const {
			emptyColumns,
			gutter,
			columnSpans,
			displaySpans,
			layoutColumns,
			unitsToRemove,
			isMobile,
			mobileStack,
			mobileHideEmpty,
			elWidth,
			gutterWidth
		} = props;

		const {
			activeIndex,
			delta,
			isDraggingHandle,
			adminWindow,
			showGhost,
			loaded,
			buttonHovered,
			focused,
			noPointerEvents,
			columnSetAbove,
		} = state;



		let emptySet = _.uniq(emptyColumns).length ==1  && emptyColumns[0] == true;
		let columnsAreUniform = _.uniq(columnSpans).length == 1 && columnSpans[0] == undefined;

		let spanPxWidth = (elWidth - (gutterWidth*(layoutColumns-1)) ) / layoutColumns;
		
		let enableResize = columnSpans.length < layoutColumns;

		let columnGhosts = [];
		let columnSpansAreDivisible = (layoutColumns%columnSpans.length == 0) || !columnsAreUniform;
		let numberOfGhostColumns = (columnSpansAreDivisible) ? layoutColumns : (columnSpans.length-unitsToRemove);
		if( isMobile && mobileStack){
			numberOfGhostColumns = 1;
		}		
		let ghostSpanWidth = Math.max(0,(elWidth - (gutterWidth*(numberOfGhostColumns-1)) ) / numberOfGhostColumns)

		// always have at least onepx of ghost gutter or else the column gaps won't be visible
		let ghostGutterWidth = Math.max(1, gutterWidth)
		ghostSpanWidth = ghostSpanWidth - (ghostGutterWidth-gutterWidth);

		const buttonHeight = 23;
		
		for(let index = 0; index < numberOfGhostColumns; index++){

			const positionLeft = (ghostSpanWidth+ ghostGutterWidth)*index 
			columnGhosts.push(<div
				key={`ghost-${index}`}
				className="ghost-span"
				style={{
					transform: `translateX(${positionLeft}px)`,
					opacity: showGhost ? .04 : 0,
					width: ghostSpanWidth+'px',
				}}
			></div>)
		}

		return (<>
			<SelectionStatus baseNode={this.props.columnInstance}/>

			<style>{emptyColumns.map((empty, index)=>{
				return empty ? `
				::slotted(column-unit:nth-child(${index+1})){
					min-height: 1em;
				}`: ''
			}).join('\n')}</style>
			<EditorOverlay
				onPointerIn={this.checkForColumnSetAbove}
				onScrollWhileHovered={this.updateUIWindowPosition}
				trackResize={true}
				buttonHeight={buttonHeight}
				buttonMode="outside"
				baseNode={this.props.columnInstance}
				el={this.props.columnInstance}
				render={(overlayProps)=>{

					const {
						pointerAttention,
						overlayPosition,
					} = overlayProps;

					let cumulativeSpans = 0;
					let cumulativeSize = 0;

					let dip = -buttonHeight;

					// depending on the position of the button, we might move it over to make it fit inside the outline
					let xDip = 2;

					// if there isn't enough room at the top for the button...					
					if( overlayPosition.y <= buttonHeight || columnSetAbove ){

						// then put the button at the bottom of the overlay
						dip = overlayPosition.height+1;

						// if the height + button + position overflow the window, set it at the bottom, inside the overlay
						if( overlayPosition.height + overlayPosition.y > windowInfo.data.window.h +-buttonHeight ){
							dip = -overlayPosition.y + windowInfo.data.window.h +-buttonHeight+1;
							xDip = 0;
						}
						
					}

					return <div
						ref={this.columnOutlineRef}
						className={`editor-overlay editor-outline columns-outline${pointerAttention ? ' hover': ''}${pointerAttention ? ' button-hover': ''}${focused ? ' focus': ''}`}
						style={{
							transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 0)`,
							width: overlayPosition.width +'px',
							height: overlayPosition.height+'px',
							display: (pointerAttention || buttonHovered || focused) ? 'block': 'none',
						}}

					>
						{(pointerAttention || focused || buttonHovered) && columnGhosts}
						{(pointerAttention || focused || buttonHovered) && !(isMobile && mobileStack) ? columnSpans.map((span, index)=>{

							let columnDisplay = displaySpans[index];
							// if span is undefined, just subdivide equally:
							if( !span ){
								span = layoutColumns/(columnSpans.length);

							}

							if( index == columnSpans.length -1 || columnDisplay == 'hide'){
								return null;
							}

							// if there is more than one empty column at the end of the set, use this to check that we don't render a handle for it
							cumulativeSpans+=span
							if( cumulativeSpans >= layoutColumns){
								return null;
							}

							cumulativeSize += spanPxWidth * span + (span-1) * gutterWidth + gutterWidth;

							let positionX = `${cumulativeSize+-gutterWidth*.5}px`
							let marginLeft = this.handleWidth *-.5;
							if( gutterWidth <= this.handleWidth-this.minHandleOverlap ){
								marginLeft = gutterWidth * -.5 +-this.minHandleOverlap;
							}

							return <>
								{(enableResize && index < layoutColumns) && <div
									style={{
										pointerEvents: noPointerEvents ? 'none': 'auto',
										display: (pointerAttention || focused || buttonHovered) ? 'block': 'none',										
										transform: `translateX(${positionX})`,
										width: this.handleWidth+'px',
										marginLeft: `${marginLeft}px`,
									}}
									className={`column-divider-handle`}
									onMouseDown={(e)=>this.onMouseDown(e, index)}
								></div>}
								<div
									style={{
										display: (pointerAttention || focused || buttonHovered) ? 'block': 'none',
										transform: `translateX(${positionX})`,
										transition: isDraggingHandle ? 'transform .08s cubic-bezier(0, 0, 0.2, 1)' : ''
									}}
									key={`col-${index}`}
									className={`column-divider`}
								></div>
							</>

						}): null }
						
						 {/* - 23 */}
						<div
							className="in-editor-buttons"
							style={{
								pointerEvents: noPointerEvents ? 'none': 'auto',
					    		transform: `translate(${xDip}px, ${dip}px)`
							}}
							onPointerEnter={()=>{this.setState({buttonHovered: true})}}
							onPointerLeave={()=>{this.setState({buttonHovered: false})}}

						>
							<div className="pointer-mask">
							<button
								className="columns text"
								ref={this.gearReaf}
								onMouseDown={this.launchUIWindow}
							>
								{/* <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"> */}
								{/* 	<path d="M12.4003 13.8247L13.7502 14.7991L14.8005 13.7487L13.8261 12.3989C14.0511 12.0234 14.2761 11.4989 14.3506 11.049L16 10.7495V9.2491L14.3506 8.9496C14.2001 8.49963 14.0511 7.97516 13.8261 7.59971L14.8005 6.24982L13.7502 5.19942L12.4003 6.17386C12.0248 5.94888 11.5003 5.7239 11.0504 5.64939L10.7495 4H9.2491L8.9496 5.64939C8.49963 5.79984 7.97516 5.94889 7.59971 6.17386L6.24982 5.19942L5.19942 6.24982L6.17386 7.59971C5.94888 7.97516 5.7239 8.49963 5.64939 8.9496L4 9.25051V10.7509L5.64939 11.0504C5.79984 11.5003 5.94889 12.0248 6.17386 12.4003L5.19942 13.7502L6.24982 14.8005L7.59971 13.8261C7.97516 14.0511 8.49963 14.2761 8.9496 14.3506L9.25051 16H10.7509L11.0504 14.3506C11.5003 14.2747 11.9503 14.0497 12.4003 13.8247ZM7.75016 10C7.75016 8.72464 8.7246 7.7502 9.99998 7.7502C11.2754 7.7502 12.2498 8.72464 12.2498 10C12.2498 11.2754 11.2754 12.2498 9.99998 12.2498C8.7246 12.2498 7.75016 11.2754 7.75016 10Z" class="button-fill"/> */}
								{/* </svg> */}
								Columns
							</button>
							{(!isMobile|| this.props.isMobileOnly) && <>
								<button
									onMouseDown={this.removeColumn}
									className="remove"
								>
									<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<rect x="4" y="9.5" width="12" height="1" class="button-fill"/>
									</svg>
								</button>				
								<button
									onMouseDown={this.addColumn}
									className={`add ${columnSpans.length >= this.maxColumns ? 'disabled' : ''}`}
								>
									<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<rect x="4" y="9.5" width="12" height="1" class="button-fill"/>
										<rect x="9.5" y="4" width="1" height="12" class="button-fill"/>
									</svg>
								</button>
							</>}
							</div>
						</div>

					</div>
			}}/>
		</>);
	}

	checkForColumnSetAbove =()=>{
		const colRect = this.props.columnInstance.getBoundingClientRect();
		// check for column set in a conflicting area above this one:		
		document.getElementById('editor-overlay').style.display='none';
		const elementAbove = document.elementFromPoint(colRect.right-10, colRect.top-15);
		document.getElementById('editor-overlay').style.display='';
		this.setState({
			columnSetAbove: elementAbove?.tagName?.startsWith('COLUMN-')
		})
	}


	launchUIWindow = (e)=>{
		
		if( e){

			if (e.metaKey || e.button == 2){
				return;
			}

			e.preventDefault();
			e.stopPropagation();
		}

		const svgRect = this.gearReaf.current.getBoundingClientRect();
		const colRect = this.props.columnInstance.getBoundingClientRect();
		const svgPosition = {
			x: svgRect.x + 0,
			y: colRect.y + -190,
			left: svgRect.left + 0,
			right: svgRect.right + 0,
			top: colRect.top + 0 + -190,
			bottom: colRect.top + 27 + -190,
			width: svgRect.width,
			height: svgRect.height
		}

		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'columns-ineditor',
			windowAlias: 'columns',
			supportsMobile: true,
			positionRect: svgPosition,
			closeButton: false,
			closeOnAllClickout: true,
			props: {columnNodes: [this.props.columnInstance]}
		});	
	}

	addColumn= (e)=>{

		e?.preventDefault();
		e?.stopPropagation();

		if(this.props.columnSpans.length >= this.maxColumns){
			return;
		}

		CargoEditor.mutationManager.execute(()=>{

			let newColumn = this.columnCache.pop() || document.createElement('column-unit');
			newColumn.removeAttribute('span');
			newColumn.removeAttribute('slot');
			newColumn.removeAttribute('data-discarded-column');
			
			this.props.columnInstance.appendChild(newColumn);

			// if addcolumn is triggered frquently, just straighten out the columns while adding/removing
			if( this.addThreshold > 1 ){
				this.updateColumns(this.props.columnSpans.map(span=>undefined));
			}

			this.addThreshold++;
			clearTimeout(this.addTimeout)
			this.addTimeout = setTimeout(()=>{
				this.addThreshold = 0;
			}, 500)
		
		})

		this.props.columnInstance.render();
		this.saveColumnSettings();

	}

	removeColumn = (e)=>{

		e?.preventDefault();
		e?.stopPropagation();
		
		let copyContents = e?.altKey || false;

		const childElements = Array.from(this.props.columnInstance.children).filter(node=>node.tagName == 'COLUMN-UNIT' );

		if(childElements.length == 0){
			return
		}

		CargoEditor.mutationManager.execute(()=>{
			// CargoEditor.plugins.columns.allowColumnRemoval = true;
			if(this.props.columnSpans.length == 1){

				// add breaks before and after to maintain 'column' formatting

				Array.from(childElements[0].childNodes).forEach(node=>{
					this.props.columnInstance.parentNode.insertBefore(node, this.props.columnInstance);
				})
				this.props.columnInstance.parentNode.insertBefore(document.createElement('br'), this.props.columnInstance);

				this.props.columnInstance.remove();


			} else {


				let columnToRemove = childElements[childElements.length-1];

				if( copyContents ){
					let destinationColumn = childElements[childElements.length-2];
					Array.from(columnToRemove.childNodes).forEach(node=>{
						destinationColumn.appendChild(node);
					})
				}

				this.columnCache.push(columnToRemove.cloneNode(true));

				this.waiting = true;
				columnToRemove.remove();
				columnToRemove.setAttribute('data-discarded-column', true);

				// if addcolumn is triggered frquently, just straighten out the columns while adding/removing
				if( this.removeThreshold > 1 ){
					this.updateColumns(this.props.columnSpans.map(span=>undefined));
				}

				this.removeThreshold++;
				clearTimeout(this.removeTimeout)
				this.removeTimeout = setTimeout(()=>{
					this.removeThreshold = 0;
				}, 500)	

			}

		})

		this.props.columnInstance.render();

	}

	



	updateUIWindowPosition = (scrollDelta)=>{

		if(!this.state.focused || !this.state.UIWindow){
			return;
		}

		if( this.props.onScrollWhileHovered ){
			this.props.onScrollWhileHovered(scrollDelta)
		}

		this.state.UIWindow.props.superBadScrollHack.scroll(scrollDelta)

	}


	onMouseDown = (e, index)=>{

		if(e.button ==2){
			return;
		}

		if(this.props.columnSpans.length < 2){
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		this.setState({
			showGhost: true,
			isDraggingHandle: true,
			activeIndex : index,
			pointerStart :{x: e.clientX, y: e.clientY},
			referenceColumnSpans: this.props.columnSpans,
			instanceRect: this.props.columnInstance.getBoundingClientRect()			
		},()=>{

			// on a timeout to make sure the click event triggers on the columns element rather than body
			this.dragTimeout = setTimeout(()=>{
				document.body.classList.add('dragging');			
			}, 100);
			ADMIN_FRAME.adminWindow.addEventListener('pointermove', this.onPointerMove)
			ADMIN_FRAME.adminWindow.addEventListener('pointercancel', this.onPointerUp);
			ADMIN_FRAME.adminWindow.addEventListener('mouseup', this.onPointerUp);

			// editorOverlayAPI.activateHoverElement(this.props.columnInstance)
			// editorOverlayAPI.lock();
			
			window.addEventListener('pointermove', this.onPointerMove)
			window.addEventListener('pointercancel', this.onPointerUp);
			window.addEventListener('mouseup', this.onPointerUp);
		})

	}

	onPointerUp = (e)=>{

		clearTimeout(this.dragTimeout);
		ADMIN_FRAME.adminWindow.removeEventListener('pointermove', this.onPointerMove)
		ADMIN_FRAME.adminWindow.removeEventListener('pointercancel', this.onPointerUp);
		ADMIN_FRAME.adminWindow.removeEventListener('mouseup', this.onPointerUp);

		// editorOverlayAPI.unlock();

		window.removeEventListener('pointercancel', this.onPointerUp);
		window.removeEventListener('mouseup', this.onPointerUp);
		window.removeEventListener('pointermove', this.onPointerMove)

		this.setState({
			showGhost: false,
			isDraggingHandle: false,
			activeIndex :-1,
			delta: {x: 0, y: 0}
		})
		document.body.classList.remove('dragging');	
	}


	onPointerMove = (e)=>{

		e.preventDefault();
		
		const {
			activeIndex,
			pointerStart,
			instanceRect,
			referenceColumnSpans,
			isDraggingHandle
		} = this.state;

		const {
			gutterWidth,
			displaySpans,
			unitsToRemove,
			layoutColumns,
			units
		} = this.props;

		if( isDraggingHandle ){

			let columnSpans  = [...referenceColumnSpans];
			let referenceColumnsAreUniform = _.uniq(referenceColumnSpans).length == 1 && referenceColumnSpans[0] == undefined;

			if( referenceColumnsAreUniform ) {

				let accumulatedSpans = 0;
				let accumulatedError = 0;
				let accumulatedWidth = 0;

				let error = ((units)/columnSpans.length)- Math.floor((units)/columnSpans.length);
				let newSpanBase = Math.floor( (units)/(columnSpans.length) );
				
				let newColumnSpans = columnSpans.map((span, index)=>{

					accumulatedError = accumulatedError + error;
				
					let newSpan = newSpanBase
					if(accumulatedError > 1){
						newSpan = newSpan+1;
						accumulatedError = accumulatedError-1;
					}
					
					if( index == columnSpans.length-1){
						newSpan = (units) - accumulatedSpans
					}

					accumulatedSpans = accumulatedSpans + newSpan;
					return newSpan;

				})

				this.setState({
					referenceColumnSpans: newColumnSpans,
				});
				return;

			}

			let spanPxWidth = (instanceRect.width - (gutterWidth*(layoutColumns-1)) ) / layoutColumns;

			let position = 0;
			let positions = [];
			// use column spans to find the locations of the two columns being resized
			columnSpans.forEach((span, index)=>{
				// if span is undefined, just subdivide equally:
				if( !span ){
					span = layoutColumns/columnSpans.length
				}

				let pxWidth = spanPxWidth * span + (span-1) * gutterWidth;
				if(index == activeIndex || index == activeIndex+1){
					positions.push({
						x: position,
						width: pxWidth
					})
				}
				if( displaySpans[index] !='hide'){
					position += pxWidth + gutterWidth;
				}

			})		
			// hardcode a threshold to help prevent ask for a 0 or negative-sized span
			const minColumnSize = 20;
			const {left} = instanceRect;
			let delta = {
				x: e.clientX - (positions[1].x+ left+-gutterWidth*.5),
				y: e.clientY- pointerStart.y
			}
			let newColumnSpans = columnSpans.map((span, index)=>{

				let newSpan = span;
				let pxWidth = spanPxWidth * span + (span-1) * gutterWidth;
				if(index == activeIndex ){

					let newPxWidth = pxWidth + delta.x
					newSpan = (newPxWidth+gutterWidth)/ (spanPxWidth+gutterWidth);
					return Math.round(Math.max(Math.min(newSpan,(units)-1),1) );

				} else if (displaySpans[index] == 'hide'){
					return span
				} else {			
					return Math.round(Math.max(Math.min(newSpan,(units)-1),1) );
				}

			});

			// the 'adjacent' index might be an invisible column, so we don't want to move it
			// instead we search up the index for the first visible column
			let searchIndex = activeIndex+1;
			while( displaySpans[searchIndex] == 'hide' ){
				searchIndex++;
			}

			// turn the adjacent index into a remainder that always fills up the rest
			const newTotalSpans = _.sum(newColumnSpans) - newColumnSpans[searchIndex];

			newColumnSpans[searchIndex] = (units) - newTotalSpans;

			// if this process results in a 0-width span, just bring it back up by one
			if(newColumnSpans[searchIndex] == 0){
				newColumnSpans[searchIndex]+=1;
				newColumnSpans[activeIndex]-= 1;
			}

			// check to see if we've made a uniform column set. if so, then we clear out the values to maintain the uniform status
			let newColumnsAreUniform = _.uniq(newColumnSpans).length == 1 && _.sum(newColumnSpans) ==units;
			if(newColumnsAreUniform) {
				newColumnSpans = newColumnSpans.map(span=>undefined);
			} 
			if(Math.abs(delta.x) > 4){
				this.updateColumns(newColumnSpans);	
			}
			
			this.setState({
				delta
			})

		} 

	}

	checkColumns = ()=>{
		const {
			layoutColumns,
			columnSpans,
			units
		} = this.props;


		if(columnSpans.length == 0){
			return;
		}

		let columnsAreUniform = _.uniq(columnSpans).length == 1 && columnSpans[0] == undefined;

		// if no column has a span attr, let them be
		if( columnsAreUniform ){
			return;
		}

		let containsUndefinedSpans = columnSpans.includes(undefined);

		let totalSpans = _.sum(columnSpans);
		let newColumnSpans = [...columnSpans];
		let noRoomForNewSpan = false;

		// if the total spans don't equal the columns set, then distribute remaining columns amongst the remainders
		if( totalSpans != units || containsUndefinedSpans) {

			// distribute the spans evenly if there are simply too many spans in the column set
			if( totalSpans > units ){

				noRoomForNewSpan = true;

			// otherwise distribute the spans evenly
			} else if(totalSpans <= units){

				if (containsUndefinedSpans){					

					let remainder = units - totalSpans;
					let numberOfUndefinedSpans = columnSpans.filter((span)=> span == undefined).length;
					let newSpan = Math.floor(remainder / numberOfUndefinedSpans);
					columnSpans.forEach((span, index)=>{

						// if we're at the end, always fill out the remainder if there's any left
						if( index == columnSpans.length-1 && remainder > 0 ){

							newColumnSpans[index] = remainder
							
						// if a span is undefined, and the remainder is 0, we need to make room for it by cannibalizing existing columns							
						} else if (span == undefined && remainder == 0){

							let searchIndex = index
							while(searchIndex >= 0 && columnSpans[searchIndex] <2 || columnSpans[searchIndex] == undefined){
							    searchIndex--;
							}
						
							if(columnSpans[searchIndex] > 1){
								newColumnSpans[index] = Math.floor(columnSpans[searchIndex]/2)
								newColumnSpans[searchIndex] = columnSpans[searchIndex] - newColumnSpans[index];
							// no suitable span was found
							} else {
								noRoomForNewSpan = true;
							}

						} else if(span == undefined){
							newColumnSpans[index] = newSpan
							remainder-= newSpan;
						} else {
							newColumnSpans[index] = span						
						}

					});

				// when we've removed a column and all of the existing columns are defined, just combine the last two columns
				} else {

					let remainder = units - totalSpans;
					newColumnSpans[newColumnSpans.length-1] = remainder + newColumnSpans[newColumnSpans.length-1];

				}

			} 

			let newColumnsAreUniform = _.uniq(newColumnSpans).length == 1 && _.sum(newColumnSpans) == units;
			if(newColumnsAreUniform || noRoomForNewSpan) {
				newColumnSpans = newColumnSpans.map(span=>undefined);
			}
			
			this.updateColumns(newColumnSpans);

		} else {
			this.updateColumns(columnSpans);
		}

	}

	updateColumns = (newColumnSpans) => {

		const childElements = Array.from(this.props.columnInstance.children).filter(node=>node.tagName == 'COLUMN-UNIT');
		const changes = [];
		
		childElements.forEach((col, index) => {
			
			if( newColumnSpans[index] == undefined ){
				changes.push([col, null]);
			} else if(parseInt(col.getAttribute('span')) != newColumnSpans[index] ){
				changes.push([col, newColumnSpans[index]]);
			}

		});

		if(changes.length > 0) {

			CargoEditor?.mutationManager?.execute(() => {

				changes.forEach(([col, attrValue]) => {

					if(attrValue === null) {
						col.removeAttribute('span');
					} else {
						col.setAttribute('span', attrValue)
					}

				})

			});

			this.saveColumnSettings();
			this.props.columnInstance.render();
		}

	}

	saveColumnSettings = ()=>{
	    ADMIN_FRAME.adminWindow.store.dispatch({
	        type: 'UPDATE_ADMIN_STATE', 
	        payload: {
	            session: {
	                ['last-column-settings']: this.getColumnInfo()
	            }
	        }
	    });
	}

	onDragOver = (editor, e, data)=>{

		if( data.fromOutside && !data.inAdmin ){
			editorOverlayAPI.activateHoverElement(this.props.columnInstance);
			editorOverlayAPI.lockItem(this.props.columnInstance);
		} else {
			editorOverlayAPI.unlockItem(this.props.columnInstance);
			editorOverlayAPI.deactivateHoverElement(this.props.columnInstance);
		}
	}

	onDragStart = (editor, e, data)=>{
		editorOverlayAPI.activateHoverElement(this.props.columnInstance)
		editorOverlayAPI.lockItem(this.props.columnInstance);		
	}


	onDragEnd = (editor, e)=>{
		editorOverlayAPI.unlockItem(this.props.columnInstance);		
		editorOverlayAPI.testHover();
	}

	componentDidUpdate(prevProps, prevState){

		if(
			!_.isEqual(prevProps.columnSpans, this.props.columnSpans) || 
			prevProps.layoutColumns+prevProps.unitsToRemove != (this.props.layoutColumns+this.props.unitsToRemove)
		){

			// if making a drastic change to the column set, just reset the spans to a uniform width
			if ( Math.abs((prevProps.layoutColumns+prevProps.unitsToRemove) - (this.props.layoutColumns+this.props.unitsToRemove)) > 2 ){
				this.updateColumns(this.props.columnSpans.map(span=>undefined))
			} else {
				this.checkColumns();
			}
			
		}

		if(
			prevProps.gutter !== this.props.gutter || 
			prevProps['mobile-gutter'] !== this.props['mobile-gutter']
		) {
			this.updateEditorRect();
		}
	}

	updateEditorRect = _.throttle(()=>{
		
		editorOverlayAPI.updateElementRect(this.props.columnInstance);

	}, 120, {
		leading: true,
		trailing: true
	})

	getColumnInfo = ()=>{
		return {
			columnSizes: this.props.columnSpans.map((colSize)=> colSize == undefined ? 0 : colSize),
			units: this.props.units,
			columns: this.props.columnSpans.length,
			emptySet: _.uniq(this.props.emptyColumns).length ==1  && this.props.emptyColumns[0] == true
		}
	}

	onColumnAddOrRemove = (mutationsList)=>{

		let columnRemovedOrAdded = false;

		mutationsList.forEach((mutation)=>{

			if(Array.from(mutation.addedNodes).find(node=> node.tagName == 'COLUMN-UNIT') ){
				columnRemovedOrAdded = true;
			}
			if(Array.from(mutation.removedNodes).find(node=> node.tagName == 'COLUMN-UNIT') ){
				columnRemovedOrAdded = true;
			}
		})

		if(columnRemovedOrAdded ){

			this.columnObserver.disconnect();

			if( columnRemovedOrAdded){

				const childElements = Array.from(this.props.columnInstance.children).filter(node=>node.tagName == 'COLUMN-UNIT');
				childElements.forEach(col=>{

					this.columnObserver.observe(col, this.columnObserverConfig)

				})

				this.state.UIWindow?.setState?.({
					activeSettings: this.state.UIWindow.getColumnSettingsFromNodes()
				});
			}			
		}

		this.state.UIWindow?.onMutation?.();
		this.props.columnInstance.render();
	}

	onColumnChange = (mutationsList)=>{
		let spanChanged = true;
		mutationsList.forEach((mutation)=>{

			if( mutation.type == 'attributes' && mutation.attributeName ==='span'){
				spanChanged = true;
			}

		})	
		if(spanChanged){
			this.props.columnInstance.render();
			this.state.UIWindow?.onMutation?.();
		}
	}

	setColumns = (numberOfColumns)=> {

		const childElements = Array.from(this.props.columnInstance.children).filter(node=>node.tagName == 'COLUMN-UNIT');
		const totalColumns = childElements.length;

		if(totalColumns == numberOfColumns) {
			return;
		}

		if( totalColumns < numberOfColumns ){

			for( var i = 0; i < numberOfColumns - totalColumns; i++){
				let newColumn = this.columnCache.pop() || document.createElement('column-unit');
				newColumn.removeAttribute('span');
				this.props.columnInstance.appendChild(newColumn);
			}

		} else if ( numberOfColumns < totalColumns) {

			for( var i = totalColumns-1; i >= numberOfColumns; i--){
				let columnToRemove = childElements[i];
				this.columnCache.push(columnToRemove.cloneNode(true));
				columnToRemove.setAttribute('data-discarded-column', true);
				columnToRemove.remove();
			}

		}

		this.saveColumnSettings();

	}

	resetColumns = ()=>{
		this.updateColumns(this.props.columnSpans.map(span=>undefined))
	}

	setUIWindow = (UIWindow)=>{
		this.setState({
			UIWindow,
			focused: !!UIWindow,
		})
	}


	componentDidMount(){

		editorSet.add(this);

		ADMIN_FRAME.globalDragEventController.on('dragover', this.onDragOver);

		ADMIN_FRAME.globalDragEventController.on('dragstart', this.onDragStart);
		ADMIN_FRAME.globalDragEventController.on('dragend', this.onDragEnd);
		ADMIN_FRAME.globalDragEventController.on('drop', this.onDragEnd);




		this.columnAddOrRemoveObserver.observe(this.props.columnInstance, this.columnAddOrRemoveObserverConfig);

		const childElements = Array.from(this.props.columnInstance.children).filter(node=>node.tagName == 'COLUMN-UNIT');
		childElements.forEach(col=>{
			this.columnObserver.observe(col, this.columnObserverConfig)
		})

		this.checkColumns();

		this.props.columnInstance._editorInterface = this;
		
	  	const textNodesRemoved = [];
	  	Array.from(this.props.columnInstance.childNodes).forEach((node, i)=>{
	  		if( node.tagName !== 'COLUMN-UNIT'){
	  			this.props.columnInstance.removeChild(node);
	  			textNodesRemoved.push(node);
	  		}
	  	})

	  	if(textNodesRemoved.length > 0){
	  		console.warn('removed stray elements from column set', textNodesRemoved)
	  	}


		if( this.props.columnInstance._newColumnSet ){
			delete this.props.columnInstance._newColumnSet;

			// disable auto-launch when column is created
			// setTimeout(()=>{
			// 	this.launchUIWindow();
			// }, 120)
			

			editorOverlayAPI.activateHoverElement(this.props.columnInstance);
			editorOverlayAPI.lockItem(this.props.columnInstance);

			const onInteraction = (e)=>{
				this.showOutlinesTimeout = setTimeout(()=>{
					editorOverlayAPI.unlockItem(this.props.columnInstance);
					editorOverlayAPI.testHover();		
				}, 500);

				window.removeEventListener('mousedown', onInteraction);
			}
			window.addEventListener('mousedown', onInteraction)

		}

		this.testHoverTimeout = setTimeout(()=>{
			editorOverlayAPI.testHover();
		}, 300);
		
	}


	componentWillUnmount(){
		editorSet.delete(this);
		clearTimeout(this.testHoverTimeout);
		// unmount means that either we're moving out of admin mode or the element has been deleted
		// either way, time to close the ui window
		this.state.UIWindow?.closeWindow?.();

		clearTimeout(this.showOutlinesTimeout)
		this.updateColumns.cancel();

		this.columnAddOrRemoveObserver.disconnect();
		this.columnObserver.disconnect();

		ADMIN_FRAME.globalDragEventController.off('dragover', this.onDragOver);
		ADMIN_FRAME.globalDragEventController.off('dragstart', this.onDragStart);
		ADMIN_FRAME.globalDragEventController.off('dragend', this.onDragEnd);
		ADMIN_FRAME.globalDragEventController.off('drop', this.onDragEnd);


		delete this.props.columnInstance._editorInterface
	}

}


function mapReduxStateToProps(state, ownProps) {

	const pid = ownProps.pid;

	const isMobileOnly = (
		state.site.mobile_homepage_id == pid || 
		( state.pages.byId[pid] && state.pages.byId[pid].pin && state.pages.byId[pid].pin_options.screen_visibility === 'mobile' )
	)
	return {
		isMobileOnly,
	};

}

export default withPageInfo(connect(
	mapReduxStateToProps,
	null
)(ColumnEditor));
