import { Component , cloneElement, toChildArray, render, createRef} from "preact";
import { createPortal } from "preact/compat"
import { toVdom } from "./gallery/helpers";
import { connect } from 'react-redux';
import * as helpers from "@cargo/common/helpers";
import { withPageInfo } from "./page-info-context";
import ResizeCard from "./gallery/resize-card";
import TextAlignDetector from "./text-align-detector";
import windowInfo from "@cargo/common/window-info"
import MarqueeEditor from '../overlay/marquee-editor';
import { subscribe, unsubscribe, dispatch } from '../../customEvents';

import _ from 'lodash';
import register from "./register";

// run all marquees using universal timer
let marqueeMap = new Map();
let innerMap = new Map();

let marqueeAnimationFrame = null;
let resizeObserver;

if(!helpers.isServer) {

	resizeObserver = new ResizeObserver(entries => {

		entries.forEach(entry => {
			if( innerMap.has(entry.target)){

				const component = innerMap.get(entry.target);
				component.onMarqueeInnerResize(entry);

			} else if( marqueeMap.has(entry.target)){

				const component = marqueeMap.get(entry.target);
				component.onElementResize(entry);
			}

		});
		
	});	

	window.addEventListener('dom-binding-initialized', (e)=>{
		marqueeMap.forEach((component, el)=>{
			if( e.target.contains(el) ){
				el.dirty = true;
			}
		});
	});	

}

let lastTimestamp = 0;

const updateMarquees = (timestamp)=> {

	const delta = lastTimestamp=== 0 ? 0 : timestamp - lastTimestamp;
	marqueeMap.forEach((component, element) => {

		component.animate(delta);
	});
	lastTimestamp = timestamp;
	marqueeAnimationFrame = requestAnimationFrame(updateMarquees)
}


class Marquee extends Component {

	constructor(props){
		super(props);

		this.uid = _.uniqueId();

		this.initialSizeSet = false;

		this.state = {
			dirty: true,
			built: true,

			marqueeHeight: 100,
			gutterPixelWidth: 0,

			mediaItemLocations: {},

			contentRect: {
				marginLeft: 0,
				width: 0,
				height: 0,
				contentWidth: 0,
			},

			elementRect: {
				horizPad: 0,
				vertPad: 0,
				width: 0,
				height: 0,
			},

			paused: false,
			pointerInfo: {
				pointerDown: false,
				dragging: false,
			},
			marqueeInner: null,

			visible: true,
		}

		this.pointerMovement = {
			start: {
				x: 0,
				y: 0,
			},
			delta: {
				x: 0,
				y: 0
			},
			inertia: {
				x: 0,
				y: 0,
			},
		};

		this.marqueePositionerRef = createRef();
		this.marqueePositionerCloneRef = createRef();
		this.contentRef = createRef();

		this.contentsObserver = new MutationObserver(this.onContentsChange);


		// if speed is positive, start at left and move right
		this.resetPosition();
		this.pauseCount = 0;

		Object.defineProperty(this.props.baseNode, 'dragging', {
			get: ()=> { return this.state.pointerInfo.dragging },
			configurable: true
		});		

		Object.defineProperty(this.props.baseNode, 'paused', {
			get: ()=> { return this.state.paused },
			set: (val)=>{ this.setState({ paused: !!val})},
			configurable: true
		});		

		Object.defineProperty(this.props.baseNode, 'built', {
			get: ()=> { return this.state.built },
			set: (val)=>{ this.setState({ built: !!val})},
			configurable: true
		});

		// set dirty flag to command a reindex and rebuild
		Object.defineProperty(this.props.baseNode, 'dirty', {
			get: ()=> { return this.state.dirty },
			set: (val)=>{ this.setState({ dirty: !!val})},
			configurable: true
		});
	}

	render(props, state){

		const isBounce = props.behavior ==='bounce';
		const isScroll = props.behavior === 'scroll';
		const isVert = props.direction ==='vertical';
		let isHoriz = props.direction ==='horizontal';
		let isDiag = props.direction ==='diagonal';

		// no diagonal scroll since that would require three copies instead of one
		if( isDiag && isScroll){
			isDiag = false;
			isHoriz = true;
		}

		const {
			adminMode,
			pageInfo,
			baseNode,
			'vertical-height': height,
			gutter = 0,
		} = props

		const {
			contentRect,
			elementRect,
			built,
			marqueeHeight,
			gutterPixelWidth,
		} = state;

		let heightArray = helpers.getCSSValueAndUnit(height, '');
		let gutterWidth = gutter !== undefined ? helpers.getCSSValueAndUnit(gutter, 'rem').join('') : '0px';

		let resizeCardValues = {
			marqueeHeight: heightArray[1] ==='' ? `calc( var(--fit-height) * ${heightArray[0] * .01} )` : heightArray.join('')
		}

		if( gutter !== undefined && gutter !== 0){
			resizeCardValues.gutterPixelWidth = gutterWidth;
		}

		let pixelMarginHeight = gutterPixelWidth;
		let pixelMarginWidth = gutterPixelWidth;
		if( isHoriz ){
			pixelMarginHeight = 0;
		}

		if( isVert){
			pixelMarginWidth = 0;
		}

		const vertPad = (isScroll && isVert) ? 0 : 2
		const horizPad = (isScroll && isHoriz) ? 0 : 2

		return createPortal(
			<>
				{adminMode && pageInfo.isEditing && <TextAlignDetector onTextAlignmentChange={this.onTextAlignmentChange}/>}
				<style>{`

					:host {
						--marquee-vertical-padding: ${vertPad}rem;
						--marquee-horizontal-padding: ${horizPad}rem;
						--marquee-vertical-margin: ${-vertPad}rem;
						--marquee-horizontal-margin: ${-horizPad}rem;
						contain: layout;
						${built ? `
							cursor: default;
							min-height: .5em;
							${ adminMode && pageInfo.isEditing ? `
							-moz-user-select:none;
							-webkit-user-select:none;
							user-select: none;` : ''}
						`: `
							min-height: ${isBounce && !isHoriz ? marqueeHeight+'px' : 'auto'};
						`}
						box-sizing: border-box;
						height: ${ built ? (
							(isVert || isDiag) ? marqueeHeight+'px' : contentRect.height+elementRect.vertPad+'px'
						) : 'auto' };
					    display: block;
					    
					    position: relative;
						--resize-parent-width: ${elementRect.width}px;				    
					}

					.marquee-margin {
						position: relative;
						${built ? `
							pointer-events:none;
							margin-top: var(--marquee-vertical-margin);
							margin-left: var(--marquee-horizontal-margin);
							margin-right: var(--marquee-horizontal-margin);
							margin-bottom: var(--marquee-vertical-margin);
							
							${(isVert || isDiag) ? 'height: '+ marqueeHeight+'px;' : ''}
							padding-top: var(--marquee-vertical-padding);
							padding-bottom: var(--marquee-vertical-padding);
							padding-left: var(--marquee-horizontal-padding);
							padding-right: var(--marquee-horizontal-padding);						
							overflow: hidden;
					
						`: ``}
					}

					:host .marquee-contents {
						${built  ? `
							-moz-user-select:none;
							-webkit-user-select:none;
							user-select: none;
						` : `
							-moz-user-select:text;
							-webkit-user-select:text;
							user-select: text;
						`}
						-webkit-backface-visibility: hidden;
						backface-visibility: hidden;
						will-change: transform;
						position: relative;

					    display: ${built ? 'block' : 'none'};
					}

					::slotted(marquee-inner){
						pointer-events:auto;
						white-space:${(built && isHoriz) ? 'nowrap' : 'normal'};					
						display: ${built ? 'inline-block': 'block'};
						${!built && (isVert || isDiag) ? 'min-height: '+marqueeHeight+'px;': ''}
						min-width: ${elementRect.width}px;
						${ built ? `
							-moz-user-select:none;
							-webkit-user-select:none;
							user-select: none;
						` : `
						`}
										
					}

					.marquee-positioner {
						position: relative;		
						white-space:nowrap;
						${isScroll ? 'min-width: 100%;': ''}					
					}

					/** scroll mode css **/	
					.marquee-positioner.clone {
						display: ${(isBounce || !built) ? 'none': 'block'};
						left: ${isHoriz ? Math.max(contentRect.contentWidth, elementRect.width)+pixelMarginWidth : 0}px;
						top: ${isVert ? Math.max(contentRect.height, elementRect.height)+pixelMarginHeight : 0}px;			
					    position: absolute;
					}

					
				`}</style>
				<div className="marquee-margin">

					{ !built && <slot name="contents"/> }
					{adminMode && pageInfo.isEditing && !helpers.isServer && <MarqueeEditor
							{...this.props}
							marqueeInstance={baseNode}
							isDragging={this.state.pointerInfo.dragging}
						/>
					}		
					<ResizeCard
						values={resizeCardValues}
						onResize={this.onResize}
					/>			

					<div
						className="marquee-contents"
						style={{
							willChange: 'transform',
							transform: 'translate('+this.lastXClamp+'px, '+(this.lastYClamp+ elementRect.vertPad*.5)+'px)'
						}}
						ref={this.contentRef}
					>
						<div
							className="marquee-positioner"
							ref={this.marqueePositionerRef}
						>
							{built && <slot name="contents"/>}
						</div>
						<div
							aria-hidden="true"
							className="marquee-positioner clone"
							ref={this.marqueePositionerCloneRef}
						>
						{built && isScroll && <slot name="contents-clone"/>}
						</div>
				</div>
				</div>
			</>, baseNode.shadowRoot)
	}

	onClickCapture = (e)=>{

		// prevent click of <a> while dragging
		if ( this.state.pointerInfo.dragging && this.state.built){
			e.preventDefault();
		} 		

	}

	onDragStartCapture = (e)=>{

		if( this.state.built && this.props['pointer-interaction'] == true){
			e.preventDefault();
			e.stopPropagation();
		}
	}

	onViewportIntersectionChange = ()=>{
		Array.from(this.props.baseNode.querySelectorAll('*')).forEach((el)=>{
			dispatch(el, 'viewportIntersectionChange', {
				hasLayout: true,
				visible: true,
				position: 'inside'
			}, {
				bubbles: false,
			});
		})
	}

	onLazyloadIntersectionChange = ()=>{
		Array.from(this.props.baseNode.querySelectorAll('*')).forEach((el)=>{
			dispatch(el, 'lazyLoadIntersectionChange', {
				hasLayout: true,
				visible: true,
				position: 'inside'
			}, {
				bubbles: false,
			});
		})
	}

	componentDidMount(){

		this.props.baseNode.addEventListener('click', this.onClickCapture, {capture: true});
		this.props.baseNode.addEventListener('dragstart', this.onDragStartCapture, {capture: true});

		this.props.baseNode.addEventListener('mousedown', this.onMouseDown);
		this.props.baseNode.addEventListener('touchstart', this.onMouseDown);		

		subscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange);
		subscribe(this.props.baseNode, 'lazyLoadIntersectionChange', this.onLazyloadIntersectionChange);

		// if this is the first marquee on the page, start the animation loop 
		if ( marqueeMap.size == 0){
			cancelAnimationFrame(marqueeAnimationFrame);
			marqueeAnimationFrame = requestAnimationFrame(updateMarquees)
		}

		marqueeMap.set(this.props.baseNode, this)
		resizeObserver.observe(this.props.baseNode);		
		this.innerMarqueeChange();


	}


	componentDidUpdate(prevProps, prevState){


		// rebuild marquee if we're in the admin interface, modifying options
		if(
			this.props.behavior !== prevProps.behavior ||
			this.props.speed !== prevProps.speed ||
			this.props['vertical-height'] !== prevProps['vertical-height']
		){
			this.resetPosition();	
			this.setState({
				built: true
			})
		}

		if( this.state.dirty !== prevState.dirty && this.state.dirty ){
			this.innerMarqueeChange();
		}

		if(this.state.built !== prevState.built && this.state.built){
			if(this.state.dirty){
				this.innerMarqueeChange();				
			}

			this.resetPosition();
		}

		if( prevProps.adminMode !== this.props.adminMode){
			this.setState({
				built: true
			})
		}

	}

	componentWillUnmount(){

		this.props.baseNode.removeEventListener('dragstart', this.onDragStartCapture, {capture: true});
		this.props.baseNode.removeEventListener('mousedown', this.onMouseDown);
		this.props.baseNode.removeEventListener('touchstart', this.onMouseDown);		
		this.props.baseNode.removeEventListener('click', this.onClickCapture, {capture: true});

		unsubscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange);
		unsubscribe(this.props.baseNode, 'lazyLoadIntersectionChange', this.onLazyloadIntersectionChange);

		document.body.removeEventListener('pointerleave', this.onPointerUp)
		
		resizeObserver.unobserve(this.props.baseNode);
		marqueeMap.delete(this.props.baseNode)

		cancelAnimationFrame(this.resizeFrame);
		clearTimeout(this.afterPointerUp);


		let marqueeInner = this.props.baseNode.querySelector('marquee-inner');

		if( marqueeInner ){
			innerMap.delete(marqueeInner);
			resizeObserver.unobserve(marqueeInner);	
		}
		this.contentsObserver.disconnect();		

		// reset animation to 0 if this is the last marquee on a page
		if ( marqueeMap.size == 0){
			cancelAnimationFrame(marqueeAnimationFrame);
		}

	}

	innerMarqueeChange =()=>{

		if( !this.state.dirty ){
			return;
		}

		if( !this.state.built ){
			return;
		}

		if ( !this.marqueePositionerRef.current || !this.marqueePositionerCloneRef.current){
			return;
		}


		let marqueeInner = Array.from(this.props.baseNode.querySelectorAll('marquee-inner')).find(inner=>{
			return !inner.hasAttribute('slot') || inner.getAttribute('slot') === 'contents'
		});

		// if there's no media inner item, we create it and put all of the stuff inside it
		if( marqueeInner ){

			innerMap.delete(marqueeInner);
			resizeObserver.unobserve(marqueeInner);			
		
			// remove any nested marquee-inners
			Array.from(marqueeInner.querySelectorAll('marquee-inner')).forEach(el=>{
				el.remove();
			})

		// if there's no inner, it's newly created or bugged - create one and append the content
		} else{
			marqueeInner = document.createElement('marquee-inner');
			marqueeInner.setAttribute('slot', 'contents');

			Array.from(this.props.baseNode.childNodes).forEach(node=>{
				marqueeInner.appendChild(node);
			})
			this.props.baseNode.appendChild(marqueeInner);
		}

		
		marqueeInner.setAttribute('slot', 'contents');
		marqueeInner.setSaveable(true);

		innerMap.set(marqueeInner, this);
		resizeObserver.observe(marqueeInner);
		this.contentsObserver.observe(marqueeInner, {childList: true, subtree: true, characterData: true, attributes: false})							

		// look for clone
		let marqueeInnerClone = this.props.baseNode.querySelector('marquee-inner[slot="contents-clone"]');

		// if it's not there, make it
		if( !marqueeInnerClone ){
			marqueeInnerClone = document.createElement('marquee-inner');
			marqueeInnerClone.setAttribute('slot', 'contents-clone');
			this.props.baseNode.appendChild(marqueeInnerClone);			
		}

		while(marqueeInnerClone.lastChild){
			marqueeInnerClone.lastChild.remove();
		}

		Array.from(marqueeInner.childNodes).forEach(node=>{
			marqueeInnerClone.appendChild(node.cloneNode(true));
		});

		// do cleanup 
		Array.from(this.props.baseNode.childNodes).forEach(node=>{
			if( node !== marqueeInner && node !== marqueeInnerClone){
				node.remove();
			}
		})		


		this.setState({
			dirty: false,
			marqueeInner,
		})

	}

	onContentsChange = ()=>{
		this.setState({
			dirty: true
		});
	}

	resetPosition = ()=>{
		this.pauseCount = this.props.adminMode ? -30 : 0 ;

		this.movingDown = this.props.speed > 0
		this.movingRight = this.props.speed > 0;

		this.lastXClamp = 0;
		this.lastYClamp = 0;
		this.positionX = 0;
		this.positionY = 0;
		this.translateX = 0;
		this.translateY = 0;

		this.pointerMovement = {
			start: {
				x: 0,
				y: 0
			},
			inertia: {
				x: 0,
				y: 0,
			},
			delta: {
				x: 0,
				y: 0
			},			
		}


		if( this.props.behavior === 'bounce'){
			if( this.props.direction === 'diagonal' || this.props.direction ==='vertical'){
				this.translateY = this.positionY = this.state.elementRect.height*.5 + -this.state.contentRect.height*.5;
			}

			if( this.props.direction === 'diagonal' || this.props.direction ==='horizontal'){
				this.translateX = this.positionX = this.state.elementRect.width*.5 + -this.state.contentRect.width*.5 + this.state.elementRect.vertPad*.5
			}
		} else {
			this.translateX = this.positionX = this.translateY = this.positionY = 0
		}



	}

	onResize = (key, size) =>{

		this.setState((prevState)=>{

			const newState = {...prevState};
			newState[key] = size;

			return newState
		})
	}

	onTextAlignmentChange = (alignment)=>{
		this.resetPosition();
		this.requestTick();
	}

	requestTick = ()=>{
		if(this.ticking){
			return;
		}
		this.ticking = true;

		this.rAf = requestAnimationFrame(()=>{
			this.getContentWidthAndMargin();
			this.ticking = false;
		})
	}

	getContentWidthAndMargin = _.debounce(()=>{

		const {
			marqueeInner
		} = this.state;
		if( !marqueeInner){
			return;
		}

		let {
			contentWidth,
			marginLeft,
			width
		} = this.state.contentRect;


		let firstNode = marqueeInner.childNodes[0];
		let lastNode = marqueeInner.childNodes[marqueeInner.childNodes.length-1];

		if( firstNode && lastNode){

			const range = document.createRange();
			range.setStartBefore(firstNode);
			range.setEndAfter(lastNode);
			const rangeRect = range.getBoundingClientRect();

			const innerRect = marqueeInner.getBoundingClientRect();

			marginLeft= rangeRect.left - innerRect.left,
			contentWidth = rangeRect.width;

		} else {
			marginLeft = 0;
			contentWidth = width;
		}

		this.setState((prevState)=>{

			return {
				contentRect: {
					...prevState.contentRect,
					marginLeft,
					contentWidth
				}
			}
		},()=>{
			if( !this.initialSizeSet){
				this.resetPosition();
				this.initialSizeSet = true;
			}			
		})

	}, this.initialSizeSet ? 300 : 0, {leading: true, trailing: true})

	onMarqueeInnerResize = (entry)=>{

		let box = entry.borderBoxSize[0] || entry.borderBoxSize;
		let width = box.inlineSize;

		this.setState((prevState)=>{

			return {
				contentRect: {
					...prevState.contentRect,
					width: box.inlineSize,
					height: box.blockSize
				}
			}
		}, ()=>{
			this.requestTick();
		})

	}

	onElementResize = (entry) => {

		let box = entry.borderBoxSize[0] || entry.borderBoxSize;
		let contentBox = entry.contentBoxSize[0] || entry.contentBoxSize;

		this.setState({
			elementRect: {
				width: box.inlineSize,
				height: box.blockSize,
				vertPad: box.blockSize - contentBox.blockSize,
				horizPad: box.inlineSize - contentBox.inlineSize,
			}
		})

	}

	onMouseDown=(e)=>{
		if( !this.state.built || !this.props['pointer-interaction']){
			return;
		}

		if( e.button == 2 ){
			return;
		}

		const x = e.touches ? e.touches[0].clientX : e.clientX;
		const y = e.touches ? e.touches[0].clientY : e.clientY;

		// preventDefault if we're not on a link
		// if we are on a link, the clickcapture will handle it
		if ( !e.target.closest('a') ){
			e.preventDefault();
		}

		this.pointerMovement = {
			start: {
				x: x,
				y: y
			},
			inertia: {
				x: 0,
				y: 0,
			},
			delta: {
				x: 0,
				y: 0
			},			
		}

		this.setState({
			pointerInfo: {
				pointerDown: true,
				dragging: false,
			}
		});

		window.addEventListener('pointermove', this.onPointerMove)
		window.addEventListener('pointerup', this.onPointerUp)
		window.addEventListener('pointercancel', this.onPointerUp)	
		document.body.addEventListener('pointerleave', this.onPointerUp)
	}

	onPointerMove = (e)=>{

		// if we waited a bit before dragging, let's allow selection(?)		
		const delta = {
			x: (e.clientX - this.pointerMovement.start.x) + this.pointerMovement.delta.x,
			y: (e.clientY - this.pointerMovement.start.y) + this.pointerMovement.delta.y,
		}

		let dragging =(
			this.state.pointerInfo.dragging ||
			Math.abs(e.clientX - this.pointerMovement.start.x) > 3 ||
			Math.abs(e.clientY - this.pointerMovement.start.y) > 3
		);

		this.pointerMovement= {
			start: {
				x: e.clientX,
				y: e.clientY
			},
			inertia: delta,
			delta: delta,
		}

		this.setState({
			pointerInfo: {
				pointerDown: true,
				dragging: dragging
			}
		});
	}

	onPointerUp = (e)=>{

		document.body.removeEventListener('pointerleave', this.onPointerUp)
		window.removeEventListener('pointermove', this.onPointerMove)
		window.removeEventListener('pointerup', this.onPointerUp)
		window.removeEventListener('pointercancel', this.onPointerUp);

		this.afterPointerUp = setTimeout(()=>{
			this.setState({
				pointerInfo: {
					dragging: false,
					pointerDown: false,
				}
			});
		}, 0);

	}


	animate = (delta) => {
		let {
			built,
			paused,
			elementRect,
			contentRect,
			pointerInfo,
			mediaItemLocations,
			gutterPixelWidth,
		} = this.state;

		let {
			positionX,
			positionY,
			movingRight,
			movingDown,
			translateX,
			translateY,
			pointerMovement
		} = this;		

		let {
			speed,
			friction
		} = this.props;	


		if ( !built || !this.contentRef.current ){
			return;
		}

		let updatePointerInertia = false;

		speed = (paused || pointerInfo.dragging) ? 0 : speed;

		if( this.pauseCount < 0){
			this.pauseCount++;
			speed = 0;
		}

		speed = speed * (delta/16.6667);

		const isBounce = this.props.behavior ==='bounce';
		const isScroll = this.props.behavior === 'scroll';
		const isVert = this.props.direction ==='vertical';
		let isHoriz = this.props.direction ==='horizontal';
		let isDiag = this.props.direction ==='diagonal';

		let pixelMarginWidth = gutterPixelWidth;
		let pixelMarginHeight = gutterPixelWidth;

		// no diagonal scroll since that would require three copies instead of one
		if( isDiag && isScroll){
			isDiag = false;
			isHoriz = true;
		}		

		// scale speed by baseunit to achieve some sort of uniform scale of movement		
		// speed = speed*(windowInfo.data.baseUnit*.00125);		
		friction = 1-(parseFloat(friction)*.01);	
		speed = parseFloat(speed)*.1

		let deltaY = speed;
		let deltaX = speed;

		let factorInInertia =  (Math.abs(pointerMovement.inertia.y) > .01 || Math.abs(pointerMovement.inertia.x) > .01);

		if( pointerInfo.pointerDown || pointerInfo.dragging){

			updatePointerInertia = true;

			deltaX = pointerMovement.delta.x;
			deltaY = pointerMovement.delta.y;

		} else if (factorInInertia){

			updatePointerInertia = true;

			if( isBounce  ){

				if( movingDown){
					deltaY = Math.abs(deltaY) + Math.abs(pointerMovement.inertia.y)
				} else {
					deltaY = -Math.abs(deltaY) + -Math.abs(pointerMovement.inertia.y)
				}
				if( movingRight){
					deltaX = Math.abs(deltaX) + Math.abs(pointerMovement.inertia.x)
				} else {
					deltaX = -Math.abs(deltaX) + -Math.abs(pointerMovement.inertia.x)
				}

			} else {
				deltaX = deltaX + pointerMovement.inertia.x;
				deltaY = deltaY + pointerMovement.inertia.y;				
			}

		}

		if( isBounce && !pointerInfo.dragging ){

			if( movingDown){
				deltaY = Math.abs(deltaY)
			} else {
				deltaY = -Math.abs(deltaY)
			}
			if( movingRight){
				deltaX = Math.abs(deltaX)
			} else {
				deltaX = -Math.abs(deltaX)
			}			
		}

		if( deltaY !== 0){
			movingDown = deltaY > 0
		} 

		if( deltaX !== 0){
			movingRight = deltaX > 0
		} 		
		

		if( isVert || isDiag ){
			positionY = deltaY + positionY
		}

		if( isHoriz || isDiag) {
			positionX = deltaX + positionX
		}


		let dispatchBounceEvent = false;
		if ( isBounce ){
			const width = contentRect.contentWidth;
			const height = contentRect.height;

			// by default, it will bounce to the edges
			let minX = -contentRect.marginLeft;
			let maxX = elementRect.width + -width + -contentRect.marginLeft;
			let minY = 0;
			let maxY = elementRect.height - height;						
			
			// if the marquee crops the box, then the range goes elementRect.width - width to 0
			if( width >= elementRect.width ){
				minX = elementRect.width - width;
				maxX = 0;
			}

			if( height >= elementRect.height ){
				minY = elementRect.height - height;
				maxY = 0;
			}

			// if moving right and overlapping the right edge
			if( movingRight && positionX > maxX && (isHoriz || isDiag)){
				dispatchBounceEvent = 'right';
				positionX = maxX;
				movingRight = false;

			// if moving left and overlapping the left edge
			} else if ( !movingRight && positionX < minX && (isHoriz || isDiag)){
				dispatchBounceEvent = 'left'
				positionX = minX;
				movingRight = true;
			}

			// if moving down and overlapping the bottom edge
			if( movingDown && positionY > maxY && (isVert || isDiag)){
				dispatchBounceEvent = 'bottom'
				positionY = maxY;
				movingDown = false;

			// if moving up and overlapping the top edge
			} else if ( !movingDown && positionY < minY && (isVert || isDiag)){
				dispatchBounceEvent = 'top'
				positionY = minY;
				movingDown = true;
			}

			if( isHoriz ){
				translateY = 0;
			} else {
				translateY = positionY ;	
			}
			

			if( isVert){
				translateX = 0;
			} else {
				translateX = positionX ;	
			}
			

		} else if ( isScroll) {

			if( isHoriz ){
				pixelMarginHeight = 0;
			}

			if( isVert){
				pixelMarginWidth = 0;
			}

			const width = Math.max(contentRect.contentWidth, elementRect.width)+pixelMarginWidth;
			const height = Math.max(contentRect.height, elementRect.height)+pixelMarginHeight;

			if( width > 0){
				positionX = (positionX-width)%width;	
			}
			if( height > 0){
				positionY =(positionY-height)%height;	
			}
			

			if( isVert ){
				translateX = 0;
				translateY = positionY;
			} else if (isHoriz){
				translateX = positionX;
				translateY = 0;
			}

		}


// trying to calculate lazy load distance from position here...
// 		let midPointX = Math.floor((  (translateX+elementRect.width*1000)%elementRect.width )/100)*100;
// 		let midPointY = 100;
// 
// 		let viewportChange = false;
// 		if( midPointX!== this.lastMidPointX ){
// 			viewportChange = true;
// 		}
// 		if( midPointY!== this.lastMidPointY ){
// 			viewportChange = true;
// 		}		


		// this.lastMidPointY = midPointY;
		// this.lastMidPointX = midPointX;
	

		if( dispatchBounceEvent){
			this.dispatchBounceEvent(dispatchBounceEvent);
		}

		// 'flush' delta values and transfer inertia over 
		const inertia = pointerInfo.dragging ? {
			x: pointerMovement.delta.x,
			y: pointerMovement.delta.y,
		} : {
			x: pointerMovement.inertia.x * friction,
			y: pointerMovement.inertia.y * friction
		}

		
		this.positionX = positionX;
		this.positionY = positionY;
		this.translateX = translateX;
		this.translateY = translateY;
		this.movingRight = movingRight;
		this.movingDown = movingDown;

		// move in .25 increments if it's moving slowly, move in 0.5px increments if not
		const roundValue = Math.abs(speed)>1 ? 2 : 4;
		const xClamp = Math.floor(this.translateX*roundValue)/roundValue;
		const yClamp = Math.floor(this.translateY*roundValue)/roundValue;

		if( xClamp !== this.lastXClamp || yClamp !== this.lastYClamp ){
			this.contentRef.current.style.transform = 'translate('+xClamp+'px, '+(yClamp + this.state.elementRect.vertPad*.5)+'px)'
		}
		
		this.lastXClamp = xClamp;
		this.lastYClamp = yClamp;

		if( updatePointerInertia ){

			this.pointerMovement = {
				...this.pointerMovement,
				inertia,
				delta: {
					x: 0,
					y: 0
				}
			}

		}

	

	}

	dispatchBounceEvent = _.debounce((bounceEdge)=>{
		this.props.baseNode.dispatchEvent(new CustomEvent('marquee-bounce', {
			detail: {
				edge: bounceEdge
			},
			bubbles: false,
			composed: true,
		}));

	}, 40, {leading: true, tailing: false})	

}

Marquee.defaultProps = {
	speed: -10,
	direction: 'horizontal',
	behavior: 'scroll',
	'pointer-interaction': true,
	'vertical-height': '100',
	'friction': 5
}

const ConnectedMarquee = withPageInfo(connect(
    (state, ownProps) => {
        return {
            adminMode: state.frontendState.adminMode
        };
    }
)(Marquee))

register(ConnectedMarquee, 'marquee-set', [
	'gutter',
	'speed',
	'direction',
	'behavior',
	'pointer-interaction',
	'vertical-height',
	'friction'
], {
	renderIntoShadow: true
}) 









