import { render, hydrate } from 'preact';
import { Provider } from 'react-redux';
import { Frontload } from 'react-frontload';
import { Router } from "react-router";
import * as helpers from "@cargo/common/helpers";
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import axios from 'axios';

import createStore from "./store";
import { actions, actionTypes } from "./actions";
import ErrorBoundary from "./errorBoundary";

import App from "./components/app";
import windowInfo from "@cargo/common/window-info"
import { runForNAmountOfFrames } from './helpers';

import "./middleware/axiosDefaults";
import { setStore as setStoreForAxiosCachePlugin } from "./middleware/axiosCache";
import "./middleware/axiosAuth";

import "./css/imports.scss";

export * from './ssr';

// conditionally load the admin API so admin can hook into frontend
if(!helpers.isServer && helpers.isAdminEdit) {
	require('./adminAPI');
}

const { store } = createStore();

let history;

if(!helpers.isServer){

	history = createBrowserHistory();

	// attach our store to the windowInfo instance
	windowInfo.setupStore(store);

	// attach store to axios cache plugin
	setStoreForAxiosCachePlugin(store);

	let storedAuth;

	try {
		storedAuth = localStorage.getItem('c3-auth');
	} catch(e) {
		console.log('unable to retrieve stored auth', e);
	}

	const searchParams = new URLSearchParams(window.location.search);

	if(searchParams.has('auth')) {

		// Auth got passed as a query string, use this. 
		try {
			
			store.dispatch({
				type: actionTypes.AUTHENTICATE_USER_FULFILLED,
				payload: {
					authenticated: true,
					data: JSON.parse(searchParams.get('auth'))
				}
			});

			// delete queryString
			searchParams.delete('auth');
			
			let newSearchParams = searchParams.toString();

			if(newSearchParams.length > 0) {
				newSearchParams = '?' + newSearchParams;
			}

			// remove from URL without reloading page
			window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + newSearchParams);

		} catch(e) {
			console.error('unable to use querystring auth', e);
		}

	} else if(storedAuth) {

		try {
			store.dispatch({
				type: actionTypes.AUTHENTICATE_USER_FULFILLED,
				payload: {
					authenticated: true,
					data: JSON.parse(storedAuth)
				}
			});
		} catch(e) {
			console.error('unable to retrieve stored auth', e);
		}
		
	}

	// prevent auto scroll restoration by the browser
	if (window.history.scrollRestoration) {
		window.history.scrollRestoration = 'manual';
	}

	// Scroll restoration for page navigation
	const oldScrollPositions = {};

	// scroll to top when navigating (not on back button)
	history.push = _.wrap(history.push, function(original) {

		// store scroll position of currently rendered content
		oldScrollPositions[window.location.pathname] = document.scrollingElement.scrollTop;

		// call the orig function
		var args = Array.prototype.slice.call(arguments);

		const newPath = args[1] || "";

		if (
			// hash scrolling is handled by the router
			(newPath.startsWith('#') && newPath.length > 1) ||
			// or we passed an option to prevent scrolling
			args.some(opt => opt.preventScrollReset === true )
		) {
			// console.log('dont reset')
			/* Do not reset page scroll */
		} else {

			const currentPID = store.getState().frontendState.activePID;
			const currentTag = store.getState().frontendState.activeTag;

			let subscriptionTimeout = null;

			const unsubscribe = store.subscribe(() => {

				clearTimeout(subscriptionTimeout);

				const state = store.getState();

				// check if new active PID is different
				if(
					(
						state.frontendState.activePID
						&& state.frontendState.activePID !== currentPID
					) || (
						state.frontendState.activeTag
						&& state.frontendState.activeTag.url !== currentTag?.url
					)
				) {

					// new content was rendered. Scroll to top
					document.scrollingElement.scrollTo({
						top: 0,
						left: 0,
						// prevent scroll-behavior: smooth
						behavior: 'instant'
					});

					// store last known site height
					let lastScrollHeight = document.scrollingElement.scrollHeight;

					// Check for site height changes for 10 more frames
					runForNAmountOfFrames(10, () => {

						// check the height after one frame
						const newScrollHeight = document.scrollingElement.scrollHeight;

						// site height jumped, scroll up
						if(lastScrollHeight !== newScrollHeight) {

							document.scrollingElement.scrollTo({
								top: 0,
								left: 0,
								// prevent scroll-behavior: smooth
								behavior: 'instant'
							});

							lastScrollHeight = newScrollHeight;

						}

					});

					// stop listening
					unsubscribe();

				} else {

					subscriptionTimeout = setTimeout(() => {
						// don't restore scroll after 6 sec
						unsubscribe();
					}, 4000)

				}


			});

		}

		args.shift();

		original.apply(this, args);

	});

	// back/forward buttons
	window.addEventListener('popstate', e => {

		const newPathname = window.location.pathname;
		const oldPathName = history.location.pathname;

		if(window.location.hash.length > 1) {
			// do not restore scroll if a hash is present
			return;
		}

		// update scroll position of currently rendered content
		oldScrollPositions[oldPathName] = document.scrollingElement.scrollTop;

		// React renders are async. Listen for active PID change
		// to make sure we have the required content rendered before scrolling down
		const currentPID = store.getState().frontendState.activePID;
		const currentTag = store.getState().frontendState.activeTag;

		// listen to store changes
		const unsubscribe = store.subscribe(() => {

			const state = store.getState();

			// check if new active PID is different
			if(
				(
					(
						state.frontendState.activePID
						&& state.frontendState.activePID !== currentPID
					) || (
						state.frontendState.activeTag
						&& state.frontendState.activeTag.url !== currentTag?.url
					)
				)
				&& oldScrollPositions.hasOwnProperty(newPathname)
			) {

				// console.log('Attempting to restore scroll pos for', newPathname, 'to', oldScrollPositions[newPathname])

				const pidBeingScrolled = state.frontendState.activePID;

				setTimeout(async () => {
				
					// reset
					let scrollAttempts = 0;
					let tryScrolling = true;

					while(
						// keep trying till we scrolled
						tryScrolling
						// make sure we only scroll when we're still rendering the same PID
						&& state.frontendState.activePID === pidBeingScrolled
					) {

						if(scrollAttempts > 10) {
							tryScrolling = false
						}

						// only scroll 
						if(document.scrollingElement.scrollHeight >= oldScrollPositions[newPathname]) {

							document.scrollingElement.scrollTo({
								top: oldScrollPositions[newPathname],
								left: 0,
								// prevent scroll-behavior: smooth
								behavior: 'instant'
							});
							
							// console.log('restored scroll position', oldScrollPositions[newPathname])
							tryScrolling = false;
						}

						scrollAttempts++;

						// wait 100ms till we try again
						await new Promise(resolve => setTimeout(resolve, 100));

					}
				})

				// stop listening
				unsubscribe();

			}

		});

	});

	// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
	// Let's also let React Frontload explicitly know we're not rendering on the server here
	const Application = (
		<Provider store={store}>
			<Router history={history}>
				<Frontload noServerRender={true}>
					<App />
				</Frontload>
			</Router>
		</Provider>
	);
	
	const root = document.body;

	if (root.querySelector('.content') !== null) {

		// Callback function to execute when mutations are observed
// 		const callback = (mutationList, observer) => {
// 			for (const mutation of mutationList) {
// 				mutation.addedNodes.forEach(node => {
// 					console.log('hydrate debug: added', node);
// 				})
// 				mutation.removedNodes.forEach(node => {
// 					console.log('hydrate debug: removed', node);
// 				})
// 			}
// 		};
// 
// 		const observer = new MutationObserver(callback);
// 
// 		observer.observe(root, { attributes: true, childList: true, subtree: true });

		hydrate(Application, root);

		// requestAnimationFrame(() => {
		// 	console.log(observer.takeRecords());
		// 	observer.disconnect();
		// })

	} else {
		
		render(Application, root);

	}

	// show body after first render/hydrate
	requestAnimationFrame(() => {
		document.body.style.opacity = '';
	})

}

export { store, history };