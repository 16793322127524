import { Component , cloneElement, toChildArray, render} from "preact";
import { createPortal } from "preact/compat"
import { connect } from 'react-redux';

import ResizeCard from "../resize-card";

import * as helpers from "@cargo/common/helpers";
import { generateColumnMap } from "../layout-helpers";
import { diffProps } from '../../../../diffProps'
import withThumbnailContent from "../thumbnail-index-generator";

import { withPageInfo } from "../../page-info-context";

import ColumnizedEditor from '../../../overlay/columnized-editor';
import windowInfo from "@cargo/common/window-info"

import _ from 'lodash';
import register from "../../register";

const layoutData = {
	name: 'columnized',
	displayName: 'Columnized',
	tagName: 'GALLERY-COLUMNIZED',
	disabledMediaItemOptions: ['scale','limit-by'],
	mediaItemOptions: [
		{
			labelName: "Column Span",
			name: "columnized-span",
			allowUnclampedTextEntry: false,
			type: "scrubber",
			value: 1,
			min: 1,
			max: 11,
			step: 1,
			numberOnlyMode: true,
		},
	],	
	options: [
		{
			labelName: "Columns",
			name: "columns",
			type: "scrubber",
			allowUnclampedTextEntry: false,			
			value: 3,
			min: 1,
			max: 12,
			step: 1,
			numberOnlyMode: true,
		},
		{
		 	labelName: "Gutter",
		 	name: "gutter",
		 	type: "composite-scrubber",
		 	addDefaultUnitToUnitlessNumber: true,
		 	value: '1rem',
		 	defaultUnit: 'rem',
		 	min: 0,
		 	max: {
				rem: 15,
				em: 20,
				px: 100,
				etc: 10,
		 	},
		 	allowedUnits: ['px', '%', 'rem', 'vmin', 'vmax', 'vw', 'vh'],
		},
	],
	mobileOptions: [
		{
			labelName: "Columns",
			name: "mobile-columns",
			allowUnclampedTextEntry: false,			
			type: "scrubber",
			value: 3,
			min: 1,
			max: 12,
			step: 1,
			numberOnlyMode: true,
		},
		{
		 	labelName: "Gutter",
		 	name: "mobile-gutter",
		 	type: "composite-scrubber",
		 	addDefaultUnitToUnitlessNumber: true,
		 	value: '1rem',
		 	defaultUnit: 'rem',
		 	min: 0,
		 	max: {
				rem: 15,
				em: 20,
				px: 100,
				etc: 10,
		 	},
		 	allowedUnits: ['px', '%', 'rem', 'vmin', 'vmax', 'vw', 'vh'],
		},
	]	
}

layoutData.mediaItemDefaults = helpers.collapseOptions(layoutData.mediaItemOptions);
layoutData.defaults = helpers.collapseOptions(layoutData.options);
layoutData.mobileDefaults = helpers.collapseOptions(layoutData.mobileOptions);

class Columnized extends Component {
	constructor(props){
		super(props);

		this.state = {
			elWidth: 1000,
			gutterWidth: 0,
			gutterHeight: 0,
			isMobile: windowInfo.data.mobile.active,
			sizeIncrement: 0,
			hasSizes: false,
		}

	}

	render(props, state){

		const {
			gutterWidth,
			gutterHeight,
			isMobile,
			hasSizes
		} = state;

		const {
			baseNode,
			adminMode,
			pageInfo,
		} = props;

		let {
			elWidth
		} = state;

		if( !hasSizes){
			elWidth = this.props.baseNode.offsetWidth;
		}


		let gutter = (this.state.isMobile && this.props['mobile-gutter'] !== undefined ) ? this.props['mobile-gutter'] : this.props.gutter;
		let gutterArr = gutter.split(' ');

		let horizontalGutter = gutterArr.length > 1 ? gutterArr[0] : gutterArr[0];
		if ( horizontalGutter.includes('rem') && isMobile ) {
			horizontalGutter = 'calc( var(--mobile-padding-offset, 1) * ' + horizontalGutter + ' )';
		}

		let verticalGutter = gutterArr.length > 1 ? gutterArr[1] : gutterArr[0];
		if ( verticalGutter.includes('rem') && isMobile ) {
			verticalGutter = 'calc( var(--mobile-padding-offset, 1) * ' + verticalGutter + ' )';
		}

		let columns = (this.state.isMobile && this.props['mobile-columns'] !== undefined ) ? this.props['mobile-columns'] : this.props.columns;		
		columns = Math.max(1, parseInt(columns)) || 1;

		let {
			columnAndGutterMap,
			columnWidth,
			gutterPixelWidth,
			halfPixels
		} = generateColumnMap(columns, gutterWidth, elWidth);

		const mediaItems = Array.from(baseNode.children).filter(node=>node.tagName == 'MEDIA-ITEM');

		let shadowMap = [];
		let columnHeights = [];
		let lastInColumn = [];
		let lastAlignedInColumn = [];
		let targetWidth = 0;

		targetWidth = (elWidth - ((columns-1)*gutterWidth) )/columns;

		for(let i = 0; i < Math.min(mediaItems.length, columns); i++ ){
			shadowMap[i] = [];
			lastAlignedInColumn[i] = [];
		}
		for(let i = 0; i < columns; i++ ){
			columnHeights[i] = {height: 0, marginTop: -gutterWidth, index: i};
		}

		let incompleteRow = true;
		let colsInFirstRow = 0;
		let i = 0;
		for(colsInFirstRow = 0; colsInFirstRow < 12;){

			let spans = parseInt(mediaItems[i]?.getAttribute('columnized-span')) || 1;
			colsInFirstRow= colsInFirstRow+ spans;

			if( colsInFirstRow >= columns){
				colsInFirstRow = columns;
				incompleteRow = false;
				break;
			}
			i++;
		}


		// const incompleteRow = columns > mediaItems.length;
		
		const figureAttributes = [];

		const mobileColumnsAreInUse = isMobile && ( !isNaN(parseInt(this.props['mobile-columns'])) && parseInt(this.props['mobile-columns']) !== parseInt(this.props.columns))
		const slottedStyleMap = [];

		mediaItems.forEach((el, index)=>{

			let columnSpans = (mobileColumnsAreInUse) ? 1 : Math.min((parseInt(el.getAttribute('columnized-span')) || 1), columns);
				columnSpans = Math.min(columnSpans, mediaItems.length);
			// the index of shortest column
			let colIndex = -1;
			const targetMarker = _.minBy(columnHeights, (marker)=>{

				colIndex++;

				// force multiple-span items to fit inside 
				// get current height of marker, then collect (column
				let columnHeightSlice = columnHeights.slice(colIndex+1, colIndex+columnSpans).map(marker=>marker.height);

				if( columnHeightSlice.length + 1 < columnSpans){
					return 9e9;
				}

				// const shortestAvailableColumnHeight = _.maxBy([marker.height, ...columnHeightSlice]);


				// using _.mean() means that if we have a set of two choices with identical taller heights, like
				// [158, 158] and [158, 0]
				// the 'mean' means that [158, 0] will be preferred as the minimum ( 158 vs , 79)
				// so that column items will 'gravitate towards' the slice with the lowest current height

				const columnBias = marker.height == 0? 0 : Math.abs((colIndex+(colIndex%2 == 0 ? .25 : .75 ) )*2-(columns));
				return _.mean([marker.height, ...columnHeightSlice]) + columnBias;
			});

			const slotName = `column-${targetMarker.index}-slot-${index}`;
			const currentTargetMarkerHeight = targetMarker.height;

			if(targetMarker.index+columnSpans > columns ){
				columnSpans = columns-targetMarker.index;
			}

			lastInColumn[targetMarker.index] = index;

			let height = 0;
			let frameHeight = null;
			let gridItemPixelWidth = (columnWidth)*columnSpans + (gutterWidth*(columnSpans-1));

			const size = el._size || {
				width: 0,
				height: 0,
				mediaSize: {
					width: 0,
					height: 0,
					padSize: 0,
				},
				mediaItemSize: {
					width: 0,
					height: 0,
					padSize: 0,
				}					
			};
			height = size.mediaItemSize.height + size.mediaItemSize.padSize;
	
			let startColumn = targetMarker.index;
			let endColumn = targetMarker.index+columnSpans+-1;
			gridItemPixelWidth = columnAndGutterMap[endColumn].right - columnAndGutterMap[startColumn].left;

			let scaleDown = (gridItemPixelWidth + -size.mediaItemSize.padSize+ -size.mediaSize.padSize)/(size.width) || 1;

			frameHeight =  halfPixels ?
				Math.round( (size.height*scaleDown + size.mediaSize.padSize ) * 2 ) / 2  || NaN:
				Math.round( size.height*scaleDown + size.mediaSize.padSize ) || NaN;


			if( Number.isNaN(frameHeight) ){
				frameHeight = 300;
			}

			let tallestOverlappingColumnMarker = targetMarker;
			let tallestHeight = targetMarker.height
			for(let i = 1; i < columnSpans; i++){

				let nextColIndex = targetMarker.index+i;

				if( columnHeights[nextColIndex].height  >= tallestHeight ){
					tallestOverlappingColumnMarker = columnHeights[nextColIndex];
					tallestHeight = columnHeights[nextColIndex].height;
				}
			}

			const oldHeight = tallestOverlappingColumnMarker.height;
			const newHeight = tallestOverlappingColumnMarker.height + size.mediaItemSize.height+size.mediaItemSize.padSize+gutterWidth;

			for(let i = 0; i < columnSpans; i++){

				let nextColIndex = targetMarker.index+i;
				if( i === 0){

					columnHeights[nextColIndex].marginTop = columnHeights[nextColIndex].marginTop + ( oldHeight - columnHeights[nextColIndex].height)
					columnHeights[nextColIndex].height = newHeight;
				} else {
					columnHeights[nextColIndex].marginTop = columnHeights[nextColIndex].marginTop + ( newHeight - columnHeights[nextColIndex].height)
					columnHeights[nextColIndex].height = newHeight;

				}
			}

			let slottedStyleItem = {}
			
			let marginTop = columnHeights[targetMarker.index].marginTop;
			columnHeights[targetMarker.index].marginTop = 0;

			slottedStyleItem.styleString = ''


			if (marginTop !== gutterHeight){
				slottedStyleItem.styleString+=` --column-gutter-height: ${marginTop+gutterHeight}px;`
			}

			if( columnSpans > 1){
				slottedStyleItem.styleString+=` --resize-parent-width:${gridItemPixelWidth}px; min-width: ${gridItemPixelWidth}px;`
			} 

			if( frameHeight !== null){
				slottedStyleItem.styleString+=` --frame-padding: ${frameHeight}px;`;
			}

			if( currentTargetMarkerHeight === 0) {
				slottedStyleItem.firstColumnItem = true;
			}
			slottedStyleItem.slotName= slotName

			slottedStyleMap.push(slottedStyleItem);

			if( !shadowMap[targetMarker.index]) {
				shadowMap[targetMarker.index] = [];
			}
			shadowMap[targetMarker.index].push(<slot name={slotName} key={slotName}/>);

			figureAttributes.push({
				slot: slotName,
			});

		});

		for( let i = 0; i < colsInFirstRow; i++){
			if( !shadowMap[i]){
				shadowMap[i] = []
			}
		}
		
		lastInColumn.forEach((index)=>{
			slottedStyleMap[index].lastInColumn = true;
		});

		mediaItems.forEach((el, index)=>{

			const attribs = figureAttributes[index] || {}
			attribs.itemResize = this.onItemResize

			const props = el._props || {}
			diffProps(el, {...props, ...attribs}, props, false, false)

		})


		return <>
			{this.props.children}
			{createPortal(
			<>
				<style>{`
				:host {
					width: var(--resize-parent-width, 100%);
					max-width: 100%;
					${incompleteRow ?
						`display: block;` : 
					    `display: flex;
					    flex-direction: row;
					    flex-wrap: wrap;
					    align-content: flex-start;
					    justify-content: center;`
					}
				    position: relative;					
					--column-gutter-width: ${gutterPixelWidth}px;
					--column-gutter-height: ${gutterHeight}px;
					margin-top: calc(var(--gutter-expand, 0) * ${gutterHeight}px);
				}

				${slottedStyleMap.map((item, index)=>{
					return `::slotted(media-item[slot="${item.slotName}"]){
						margin-top: ${item.firstColumnItem ? '0;': 'var(--column-gutter-height, 0px);'}
						${item.lastInColumn ? `
						    flex-grow: 1;
						    flex-shrink: 1;
						    flex-basis: ${columns ===1 ? '100%' : 0};						
						`: ''}
						${item.styleString}
					} `
				}).join('')}

				* {
					box-sizing: border-box;
				}	

				:host[hidden] {
					display:none;
				}

				:host .columns-column {
					${incompleteRow ? `
						display: inline-flex;
						vertical-align: top;
					` : `
					    max-width: ${Math.ceil(elWidth/columns)}px;
					`}
					min-height: 10px;			
					flex-grow: 0;
					flex-shrink: 0;
				}

				:host .columns-column.last-column {
				    flex-grow: 1;
				    flex-shrink: 1;
				    flex-basis: ${columns ===1 ? '100%' : 0};
				}
				

				:host .columns-column:not(:last-child){
				    margin-right: var(--column-gutter-width, 0px);
				}

				::slotted(media-item) {
					position: relative;
				    width: 100%;
				    max-width: none;
				    display: flex;
				}




				`}</style>
				<ResizeCard
					values={{
						gutterWidth: horizontalGutter,
						gutterHeight: verticalGutter,
						elWidth: '100%'
					}}
					onResize={this.onResize}
				/>
				{adminMode && pageInfo.isEditing && !helpers.isServer && <ColumnizedEditor
						{...this.props}
						disableResize={mobileColumnsAreInUse}
						isMobile={isMobile}
						gallerySpecificAttributes={['columnized-span']}						
						columnAndGutterMap={columnAndGutterMap}
						galleryInstance={baseNode}
						galleryComponent={this}

					/>
				}				
	 			{shadowMap.map((column, colIndex)=>{
	 				const colInfo = columnAndGutterMap[colIndex];
	 				return (
	 					<div key={`columns-column-${colIndex}`} className={`columns-column${ colIndex === columns -1 ? ' last-column': ''}`} style={{
	 						width: (colInfo.right - colInfo.left) + 'px'
	 					}}>
							{column}			 						
 						</div>
 					)
	 			})}			 				
 			</>, this.props.baseNode.shadowRoot)}
		</>
	}

	componentDidMount(){
		windowInfo.on('mobile-change', this.onMobileChange)
	}

	componentWillUnmount(){
		windowInfo.off('mobile-change', this.onMobileChange)		
	}

	onMobileChange = (isMobile)=>{
		this.setState({
			isMobile
		})
	}

	onResize = (key, size) =>{

		this.setState((prevState)=>{

			const newState = {...prevState};
			newState[key] = size;
			newState.hasSizes = true;
			return newState
		})
	}

	onItemResize = (dimensions, element)=>{

		this.setState(prevState=>{
			return {
				sizeIncrement: prevState.sizeIncrement+1
			}
		})

	}

}

Columnized.defaultProps = {
	'show-tags':true,
	'show-title':true,

	columns: layoutData.defaults.columns.value,
	// gutter default changed from 2rem to 1rem  - set default here
	// make sure behavior stays the same when there is no gutter assigned	
	// gutter: layoutData.defaults.gutter.value,
	gutter: '2rem',
	'mobile-gutter': '2rem',

}

const ConnectedColumnized = withPageInfo(withThumbnailContent(connect(
    (state, ownProps) => {
        return {
            adminMode: state.frontendState.adminMode
        };
    }
)(Columnized)))

register(ConnectedColumnized, 'gallery-columnized', [
	'thumbnail-index',
	'links-filter-index',
	'show-tags',
	'show-title',

	'thumbnail-index-metadata',
	'columns',
	'gutter',
	'mobile-columns',
	'mobile-gutter',
], {
	renderIntoShadow: true
}) 

export {layoutData};
export default Columnized

